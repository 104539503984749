<template>
  <div class="main-panel">
    <div class="w-100 h-100 d-flex flex-column align-items-center">
      <div class="w-75">
        <img class="icon" src="../assets/iconError.svg" alt="network-error" />
        <h3>{{ title }}</h3>
        <div class="sub-text">
          {{ sorry1 }} <br />
          {{ sorry2 }} <br />
        </div>
        <button class="rounded-pill border-0 end-btn" @click="onClickRetry()">
          ลองอีกครั้ง
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isNetworkProb: true,
    }
  },

  methods: {
    onClickToCheckAgen() {
      this.$router.replace({ path: '/' })
    },
    onClickRetry() {
      this.$router.go(-1)
    },
  },
  computed: {
    title() {
      if (this.$store.state.language == 'th') {
        return 'ไม่สามารถดำเนินการได้'
      } else {
        return 'Unable to proceed'
      }
    },
    sorry1() {
      if (this.$store.state.language == 'th') {
        return 'ทีทีบี ขออภัยในความไม่สะดวก'
      } else {
        return 'Sorry for the inconvenience.'
      }
    },
    sorry2() {
      if (this.$store.state.language == 'th') {
        return 'โปรดลองใหม่อีกครั้งภายหลัง'
      } else {
        return 'Please try again later.'
      }
    },
  },
  mounted() {
    window.parent.postMessage(
      {
        widgetTitle: 'กำลังติดต่อเจ้าหน้าที่',
      },
      '*'
    )
  },
}
</script>

<style scoped>
.main-panel {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  padding-top: 69.45px;
}

.chat-panel {
  overflow: scroll;
}

.icon {
  width: 45.11px;
  height: 45.11px;
  margin-bottom: 21.45px;
}

h3 {
  color: #002d63;
  margin-bottom: 16px;
  font-family: 'Ekachon';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.sub-text {
  color: #7d8999;
  margin-bottom: 16px;
  font-family: 'Ekachon';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.end-btn {
  background-color: #f68b1f;
  width: 117px;
  height: 40px;
  color: #ffffff;
  margin-bottom: 24px;
  font-family: 'Ekachon';
  font-weight: 700;
  font-size: 16px;
  padding: 8px 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
}
</style>
